<template>
  <div id="app">
    <Particles
      id="tsparticles"
      :particlesInit="particlesInit"
      :particlesLoaded="particlesLoaded"
      url="http://foo.bar/particles.json"
    />
    <Header />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Foot />
    <BackTop />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Foot from "@/views/lab/Footer.vue";
import BackTop from "@/components/BackToTop.vue";
import Header from "./views/lab/Header.vue";
import VueI18n, { IVueI18n } from 'vue-i18n'

@Component({
  components: {
    Foot,
    BackTop,
    Header,
  },
})
export default class App extends Vue {
  
  get i18n(): VueI18n {
    return this.$i18n
  }

  private mounted() {
    if (this.$route.path == "/en" && this.$store.state.language === "zh-CN") {
      this.$store.commit("changeLanguage");
      document.title = "Dr. Tie Qiu's Homepage";
      // console.log(document.title);
      return;
    }
    let lang = this.$route.query.lang;
    if (!lang) {
      var jsQuery = window.location.search.substring(1);
      var vars = jsQuery.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == "lang") {
          lang = pair[1];
          break;
        }
      }
    }
    if (lang === "en-US" && this.$store.state.language === "zh-CN") {
      this.$store.commit("changeLanguage");
    } else {
      // donothing
    }
  }
}
</script>

<style lang="scss">
@import "./style.css";
.el-tabs__item {
  font-size: 20px; /* 你想要的字体大小 */
  font-weight: 600;
}
.el-tabs__item:hover {
  color: #2d62a3;
}
.el-tabs__item.is-active {
  color: #2d62a3;
}
 
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f0f0f049;
  // word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;

  .el-dialog__body {
    font-size: 16px;
  }
  .window-wrapper {
    h3 {
      background: white;
    }
    .area-wrapper,
    .activity-wrapper,
    .copyright-wrapper {
      margin-top: 30px;
      h3 {
        color: #113f6c;
        border-top: 2px solid #113f6c;
      }
    }
    .pub-container,
    .book-wrapper,
    .project-wrapper {
      margin-top: 30px;
      h3 {
        color: #f5ae54;
        border-top: 2px solid #f5ae54;
      }
    }
    .students-list {
      h3 {
        background: transparent;
      }
    }
  }
  .students-wrapper {
    min-height: calc(100vh - 314px);
  }
}
body {
  margin: 0;
  // background: #ececec;
}
body {
  background-image: url("./assets/image/bg1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

}

.el-pagination__total{
  display: none !important;
}

.div-wrapper {
  padding: 0 30px;
  .container {
    width: 1200px;
    margin: auto;
    min-height: calc(100vh - 259px);
    box-sizing: border-box;
    padding: 30px 20px;
    text-align: left;
    background: white;
    .title {
      text-align: left;
      padding: 5px 20px;
      font-size: 20px;
      border: 1px solid #113f6c;
      display: inline-block;
    }
    .content {
      margin-top: 10px;
      min-height: 100px;
    }
  }
}
.home-wrapper {
  .students-wrapper {
    min-height: auto !important;
  }
}
// 响应式布局
@media screen and (max-width: 1200px) {
  .home-wrapper {
    .window-wrapper {
      .container {
        width: 1200px !important;
      }
    }
  }
  .page-wrapper {
    .container {
      width: 1200px !important;
    }
  }
  .div-wrapper {
    .container {
      width: 1200px !important;
    }
  }
}

// @media screen and (max-width: 980px){
//   .home-wrapper{
//     .window-wrapper{
//       .container{
//         width: 100% !important;
//       }
//     }
//   }
//   .page-wrapper{
//     .container{
//       width: 100% !important;
//     }
//   }
// }
</style>
