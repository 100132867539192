import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 界面主题语言 zh-CN 和 en-US
    language: "zh-CN",
    // 判断屏幕大小是否是手机
    isMobile: false,
    // 存储是否已经增加访问量
    hasAccess: false,
    // 主页上部分请求直接读文件 
    offModel: true,
  },
  mutations: {
    changeLanguage(state){
      if(state.language === 'zh-CN'){
        state.language = 'en-US';
        document.title = "Dr. Tie Qiu's Homepage";
      }else{
        state.language = 'zh-CN';
        document.title = "邱铁教授的个人主页";
      }
    },

    changeMobile(state){
      state.isMobile = true;
    },

    changeNotMobile(state){
      state.isMobile = false;
    },

    changeHasAccess(state){
      state.hasAccess = true;
    }
  },
  actions: {

  },
});
