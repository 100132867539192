<template>
  <div class="footer-wrapper page-wrapper">
    <div class="container">
      <div class="school-info" >
        <img :src="require('@/assets/tju-computer.png')" style="height: 60px;margin-bottom: 5px" />
        <br />
        <span
          v-if="getLanguage() === 'zh-CN'"
          style="color: white; padding-left: 5px"
        >联系地址：天津市海河教育园区雅观路135号 天津大学55楼</span>
        <span
          v-else
          style="color: white; padding-left: 2px"
        >Address：Building 55, College of Intelligence and Computing, Tianjin University Beiyangyuan Campus, No. 135 Yaguan Road, Jinnan District, Tianjin, 300350</span>
      </div>
      <div class="conference-info">
        <div class="link-wrapper">
          <div class="link-instance">
            <a
              href="https://infocom2020.ieee-infocom.org/"
              target="_blank"
            >INFOCOM</a>
          </div>
          <div class="link-instance">
            <a
              href="https://globecom2020.ieee-globecom.org/"
              target="_blank"
            >GLOBECOM</a>
          </div>
          <div class="link-instance">
            <a
              href="https://ieee-smartiot.org/"
              target="_blank"
            >SMARTIOT</a>
          </div>
        </div>
      </div>
      <div class="link-info" :class="{en: $store.state.language === 'en-US'}">
        <!-- <div style="color: white; font-size: 18px">友情链接</div> -->
        <div class="link-wrapper">
          <div class="link-instance">
            <a
              href="https://www.tsinghua.edu.cn"
              target="_blank"
            >{{ getLanguage() === 'zh-CN' ? '清华大学' : 'THU'}}</a>
          </div>
          <!-- <div class="link-instance">
            <a
              href="https://www.pku.edu.cn/"
              target="_blank"
            >{{ getLanguage() === 'zh-CN' ? '北京大学' : 'PKU'}}</a>
          </div> -->

          <!-- <div class="link-instance">
            <a
              href="https://www.cmu.edu/"
              target="_blank"
            >{{ getLanguage() === 'zh-CN' ? '卡耐基梅隆大学' : 'CMU'}}</a>
          </div> -->
          <!-- <div class="link-instance">
            <a
              href="http://www.mit.edu/"
              target="_blank"
            >{{ getLanguage() === 'zh-CN' ? '麻省理工学院' : 'MIT'}}</a>
          </div> -->

          <div class="link-instance">
            <a href="https://www.ieee.org/" target="_blank">IEEE</a>
          </div>
          <div class="link-instance">
            <a
              href="http://www.tju.edu.cn/"
              target="_blank"
            >{{ getLanguage() === 'zh-CN' ? '天津大学' : 'TJU'}}</a>
          </div>
          
          <div class="link-instance">
            <a href="https://www.acm.org/" target="_blank">ACM</a>
          </div>
          <div class="link-instance">
            <a
              href="https://www.stanford.edu/"
              target="_blank"
            >{{ getLanguage() === 'zh-CN' ? '斯坦福大学' : 'SU'}}</a>
          </div>
          <div class="link-instance">
            <a href="https://www.ccf.org.cn/" target="_blank">CCF</a>
          </div>
        </div>
      </div>  
      <div class="QR">
  
          <div style="color: white; font-size: 12px;" v-if="getLanguage() === 'en-US' "> &nbsp;&nbsp;&nbsp;&nbsp;More：</div>
          <img style="margin-left: 29%; width:94px;height: 94px;":src="require('../../assets/qr.jpg')" />
          <div style="color: white; font-size: 12px;" v-if="getLanguage() === 'zh-CN' "> &nbsp;&nbsp;&nbsp;&nbsp;获取更多信息，请关注实验室公众号</div>
          
      </div>

    </div>
    <!-- <div class="container">
      <div class="copyright-web">
        <span>All rights reserved @Tie Qiu, 2019</span>
        <span class="count" v-if="$store.state.language == 'zh-CN'">
          <i class="el-icon-data-analysis" />
          您是第
          <strong>{{accessCount}}</strong> 位访客
        </span>
        <span class="count" v-else>
          <i class="el-icon-data-analysis" />
          You are the
          <strong>{{accessCount}}</strong> visitor
        </span>
        <span>
          <i class="el-icon-s-comment"></i>
          qiutie@ieee.org
        </span>
        <span>
          <i class="el-icon-phone"></i>
          +86-22-85356782
        </span>
      </div>
    </div> -->
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import $api from "@/api";
import VueMarkdown from "vue-markdown";

@Component({
  components: {
    VueMarkdown,
  }
})
export default class Menu extends Vue {
  private accessCount: number | string = "加载中";

  switchLang() {
    const nextLocale = this.$root.$i18n.locale === 'zh-CN' ? 'en-US' : 'zh-CN'
    this.$root.$i18n.locale = nextLocale
    localStorage.setItem('lang',nextLocale)
  
  }

  getLanguage() {
    const locale = this.$root.$i18n.locale
    return locale
  }

  // mounted() {
  //   this.getAccessCount();
  //   if (!this.$store.state.hasAccess) {
  //     this.addAccessCount();
  //   }
  // }
  //
  // getAccessCount() {
  //   $api.students.findAccessCount().then(res => {
  //     if ((res as any).length > 0) {
  //       this.accessCount = (res as any)[0].amount;
  //     }
  //   });
  // }
  //
  // addAccessCount() {
  //   $api.students
  //     .addAccessCount()
  //     .then((res:any) => {
  //       //do nothing
  //       console.log(res);
  //     })
  //     .catch((error:any) => {
  //       console.log(error);
  //     });
  // }
}
</script>
<style lang="scss" scoped>
.footer-wrapper {
  // padding: 0 30px;

  .container {
    background: #113f6c;
    padding: 20px 30px;
    box-sizing: border-box;
    width: 1200px;
    margin: auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      padding-top: 0;
    }

    .QR{
      margin-top: 2.8%;
      padding-left: 20px;
      padding-right: 5px;
      // border-left: 1px solid gray;
      width: 20%;
      // margin-top: 0.5%;
      .img{
        margin-left: 10%;
      }
    }
  }
  // .link-info, .conference-info {

  //   // &.en{
  //   //   width: 250px;
  //   // }
  // }

  .school-info {
    margin-top: 1.8%;
  }
  .conference-info {
    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px solid gray;
    margin-top: 1.8%;
    width: 100px;

  }
  .link-info {
    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px solid gray;
    width: 15%;
    margin-top: 1.8%;
    // margin-right: 25%;
  }
  .link-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 300px;
    // border-bottom: 1px solid gray;
    .link-instance {
      width: 50%;
      color: ghostwhite;
      margin-top: 12px;
      // padding-left :1px;
      // margin-right: auto;
      cursor: pointer;
      text-decoration: underline;
      line-height: 16px;
      a {
        color: white;
        text-decoration: none;
      }
      &:hover {
        a {
          color: #f5ae54;
        }
      }
    }
  }
  .conference-info {
    width: 10%;
    .link-instance{
      width: 100%;
    }
  }
  .copyright-web {
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid gray;
    span {
      color: gainsboro;
      margin-right: 15px;
      &:nth-child(2n + 1) {
        color: ghostwhite;
      }
    }
    .count {
      strong {
        color: #f5ae54;
      }
    }
  }


}
// @media screen and (max-width: 1200px) {
//   .footer-wrapper {
//     .container {
//       width: 980px;
//     }
//   }
// }
// @media screen and (max-width: 980px) {
//   .footer-wrapper {
//     font-size: 13px;
//     .container {
//       width: 100%;
//     }
//     .copyright-web {
//       .count {
//         display: block;
//         margin-top: 10px;
//       }
//     }
//   }
// }
</style>