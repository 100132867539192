// import axios from 'axios';
import axios from '../utils/http';


const members = {

    // 返回成员列表
    getStudents() {
        return axios.get('api/v1/students');
    },
    // 返回教师列表
    getTeachers() {
        return axios.get('api/v1/teachers');
    }

}

export default members;
