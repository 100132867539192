// import axios from 'axios';
import axios from '../utils/http';


const students = {

    // 返回校友数据
    findAllAlumnusClient() {
        return axios.get('api/v1/alumnus/findAllAlumnusClient');
    },

    // 返回实验室成员数据
    findAllLabMembersClient() {
        return axios.get('api/v1/labMember/findAllLabMembersClient');
    },

    // 返回访问量
    findAccessCount(){
        return axios.get('api/v1/visitAmount/findVisitAmountClient');
    },

    // 访问量增加
    addAccessCount(){
        // return ''
        return axios.get('api/v1/visitAmount/visitAmountAutoincrementCliet')
    },

}

export default students;
