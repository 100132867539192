// import axios from 'axios';
import axios from '../utils/http';


const visits = {

    // 返回visit数
    getVisits() {
        return axios.get('api/v1/visits');
    },


}

export default visits;
