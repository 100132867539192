// import axios from 'axios';
import axios from '../utils/http';


const pictures = {

    // 返回所有的directions
    getPictures() {
        return axios.get('api/v1/photowall');
    }
}

export default pictures;
