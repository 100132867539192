/**
 * 科研信息接口列表
 */

// import axios from 'axios';
import axios from '../utils/http';


const research = {

    // 查询专利著作权数据
    findAllPatentsCopyrightClient(){
        return axios.get('api/v1/patentsCopyright/findAllPatentsCopyrightClient');
    },

    // 查询研究项目
    findAllresearchProjectClient(){
        return axios.get('api/v1/researchProject/findAllresearchProjectClient');
    },

    // 查询论文数据
    findAllPublicationClient(){
        return axios.get('api/v1/publication/findAllPublicationClient');
    },

    getProjects() {
        return axios.get('api/v1/projects');
    }

};

export default research;

