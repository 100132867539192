import axios from 'axios';
import router from '../router';
import store from '../store';
import { Notification } from 'element-ui';

/**
 * 网络请求提示函数
 * @param {msg.title 对应标替，msg.text 对应文字} msg
 */
const succTip = (msg: string) => {
    Notification({
        title: '请求成功',
        message: msg,
        type: 'success',
    });
};

const failTip = (msg: string) => {
    Notification({
        title: '网络异常',
        message: msg,
        type: 'error',
    });
};
/**
 * 跳转到登录页
 * 携带当前页面路由，完成登陆后返回当前页面
 */
const toLogin = () => {
    router.replace({
        path: '/login',
        query: {
            redirect: router.currentRoute.fullPath,
        },
    });
};

const errorHandle = (status: any, other: any) => {
    // 状态码panduan
    switch (status) {
        // 401 未登录状态，跳转登录页
        case 401:
            toLogin();
            break;
        // 403 token过期，清除token并跳转登录页
        case 403:
            failTip('登陆过期，请重新登录');
            // 删除token
            // to do
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        // 404 请求不存在
        case 404:
            // failTip('请求的资源不在');
            // 或者跳转至其他页面
            break;
        default:
            // console.log(other);
            failTip('服务器异常');
    }
};

// 判断当前环境设置baseURL
/*
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://47.244.176.129:8010/api/v1';
    // axios.defaults.baseURL = 'https://qtie.net/api';
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = 'http://debug.com';
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://qtie.net/api';
}
*/
// axios.defaults.baseURL = 'http://47.244.176.129:8010/';
// axios.defaults.baseURL = 'http://159.138.20.163:10608/';
axios.defaults.baseURL = 'https://smartiotlab.net/proxyApi/';
// axios.defaults.baseURL = 'http://localhost:8010/';


// 创建axios实例,设置超时时间12s
const instance = axios.create({ timeout: 1000 * 6 });

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    (config) => {
        const state: any = store.state;
        const token = state.token;
        token && (config.headers.Authorization = token);
        return config;
    },
    (error) => Promise.reject(error),
);

const retry: number = 3;
const retryDelay: number = 1000;

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    (res) => res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res),
    // 请求失败
    (error) => {
        console.log(error);
        let { response, config } = error;
        config.__retryCount = config.__retryCount || 0;
        if(config.__retryCount >= retry) {
            return Promise.reject(response);
        }

        // Increase the retry count
        config.__retryCount += 1;
        const backoff = new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, retryDelay);
        });

        return backoff.then(() => {
            return axios(config);
        })
    },
);

export default instance;
