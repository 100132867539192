// zh-CN.ts
export default {
    name:"智&nbsp;&nbsp;慧&nbsp;&nbsp;物&nbsp;&nbsp;联&nbsp;&nbsp;实&nbsp;&nbsp;验&nbsp;&nbsp;室",
    more:"&nbsp;&nbsp;&nbsp;&nbsp;获取更多信息，请关注实验室公众号",
    language:"English",
    visit:"访问量：",
    introduction:{
       Introduction: '实验室简介',
       Enrollment: '加入我们',
       Trends: '最新动态',
       Directions: '研究方向',
       Achievements: '研究成果',
    },
   

    research:{
        Research:'研究课题',
        Project:'科研项目',
        Direction:'研究方向',
     },

    achievement:{
        Achievements: '团队成果',
        Applications: '应用成果',
        Honors: '团队荣誉',
        Awards: '奖状',
        Seminalpapers: '代表论文',
        CCFA:"CCF A类",
        ESI:"ESI高被引论文"
    },

    trend:{
        Trends:"新闻动态",
        Labtrends:"实验室动态"
    },
    
    Scholarlyevents:"学术活动",

    team:{
        Team:"团队介绍",
        Teachers:"教师团队",
        PhD:"博士生",
        Master:"硕士生",
        Alumni:"校友",
        Homepage:"个人主页",
        Undergraduate :"科研助手"
    },

    Photowall:"活动照片"
}
  