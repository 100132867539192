// import axios from 'axios';
import axios from '../utils/http';


const achievements = {

    // 返回所有achievements
    getAchievements() {
        return axios.get('api/v1/achievements');
    },
    getPapers() {
        return axios.get('api/v1/papers');
    },
    getHonors() {
        return axios.get('api/v1/honors');
    }
}

export default achievements;
