<template>
  <div class="view-nav-wrapper page-wrapper">
    <div class="top-nav container">
      <div class="top-wrapper left">
        <span>
          <img
          style="width:300px; height:95px;"
          :src="require('../../assets/smart-iot2.png')"  
        />
        <!-- <img
          style="width:95px; height:95px;"
          :src="require('../../assets/logo2.png')"
            
        /> -->

          <!-- <img :src="require('../../assets/tanklab-white.png')" /> -->
        </span>
        <div class="header-title" style="margin-left: 100px" font-weight = 1000 v-if="getLanguage() === 'zh-CN'">
          智&nbsp;&nbsp;慧&nbsp;&nbsp;物&nbsp;&nbsp;联&nbsp;&nbsp;实&nbsp;&nbsp;验&nbsp;&nbsp;室 
          <!-- <VueMarkdown>{{ $t('name') }}</VueMarkdown> -->
        </div>
        <div class="header-title-en" style="margin-left: 100px" v-if="getLanguage()=== 'en-US' ">
          <div>S m a r t&nbsp;&nbsp;&nbsp;I o t&nbsp;&nbsp;&nbsp;L a b</div>
          <!-- <div>{{ $t('name') }}</div> -->
          
        </div>
        
      </div>
      <div class="top-wrapper right">
        <img style=" width: 80%;height: 40px;":src="require('../../assets/tju-logo.png')" />
        <button class="custom-button" @click="switchLang" :style="{  fontSize: '13px'}">{{ $t('language') }}</button>
        <div :style="{  fontSize: '13px', paddingLeft: '4px'}"> {{ $t('visit') }}{{ visits[0].num }}</div>
      </div>
    </div>
    <div class="nav-wrapper container">
      <div class="menu-item">
        <router-link to="/" v-if="$store.state.language === 'zh-CN'">
          {{ $t('introduction.Introduction') }}
        </router-link>
        <router-link to="/en" v-else> Home </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/Research">
          {{ $t('research.Research') }}
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/Achievement">
          {{ $t('achievement.Achievements') }}
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/Trends">
          {{ $t('trend.Trends') }}
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/News">
          {{ $t('Scholarlyevents') }}
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/Team">
          {{ $t('team.Team') }}
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/PictureFall">
          {{ $t('Photowall') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import visit from "@/api/visit";
import VueMarkdown from 'vue-markdown'

@Component(
  {
    components:{
      VueMarkdown ,
    }
  }
)
export default class Menu extends Vue {
  visits = [
    {
      num: null,
    },
  ];
  private mounted() {
    this.recordVisits();
  }

  recordVisits() {
    visit
      .getVisits()
      .then((response) => {
        this.visits = response.data.lists;
      })
      .catch((error) => {
        // 处理错误
        console.error("Error fetching home:", error);
      });
  }



  handleChangeLanguage() {
    if (this.$store.state.language === "zh-CN") {
      this.$store.commit("changeLanguage");
      this.$router.push("/en");
    } else {
      this.$store.commit("changeLanguage");
      this.$router.push("/");
    }
    // window.location.reload();
  }

  switchLang() {
    const nextLocale = this.$root.$i18n.locale === 'zh-CN' ? 'en-US' : 'zh-CN'
    this.$root.$i18n.locale = nextLocale
    localStorage.setItem('lang',nextLocale)
    // this.forceRerenderComponents()
    // this.$root.$forceUpdate();
  
  }

  forceRerenderComponents() {
    this.$root.$forceUpdate()  // 强制刷新根实例，这将重新渲染整个应用
  }
  getLanguage() {
    const locale = this.$root.$i18n.locale
    return locale
  }

}
</script>

<style lang="scss" scoped>
.view-nav-wrapper {
  width: 100%;
  text-align: center;
  // padding: 0 30px;
  box-sizing: border-box;
  .container {
    margin: auto;
  }
}

.top-nav {
  text-align: left;
  // background: #2d62a3;
  background: #245086;

  border-bottom: 1px #245086 solid;
  position: relative;
  width: 1200px;
  margin: auto;
  height: 90px;
  display: flex;
  align-items: center;
}
.top-wrapper {
  display: inline-block;
  color: white;

  height: 85px;
  padding-left: 0px;

  &.left {
    display: flex;
    flex: 1;
    span {
      margin-left: 0px;
      border-right: 1px solid rgba(66, 66, 66, 0.2);
    }
    // img {
    //   height: 85px;
    // }
    h3 {
      display: inline-block;
      font-size: 28px;
      margin: 0;
      padding-left: 5px;
      vertical-align: top;
      line-height: 60px;
    }
    h4 {
      display: inline-block;
      font-size: 18px;
      margin: 0;
      padding-left: 10px;
      vertical-align: top;
      margin-top: 20px;
    }
  }
  &.right {
    float: right;
    margin-right: 10px;
    text-align: right;
    width: 14%;
    img {
      height: 40px;
      width: 133px;
    }
    span {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      padding: 17px 17px 19px 22px;
      font-size: 16px;
      line-height: 50px;
      i {
        margin-right: 5px;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.2);
        color: #2d62a3;
        cursor: pointer;
      }
    }
  }
  .custom-button {
  display: inline-block;
  // padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #245086;
  border: none;
  text-decoration: underline;
  border-radius: 5px;
  cursor: pointer;
  }

  .custom-button:hover {
    background-color: #1e60af;
    text-decoration: none;
  }

  .custom-button:focus {
    outline: none;
    // box-shadow: 0 0 0 1px ;
  }
  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 45px;
    font-weight: 550;
    font-family:"微软雅黑";
    .header-title-top {
      font-size: 16px;

      a {
        color: #e7edf3;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .header-title-bottom {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
    }
  }

  .header-title-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 62px;
    font-weight: 550;
    .header-title-top {
      font-size: 16px;

      a {
        color: #e7edf3;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .header-title-bottom {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
    }
  }
}
.nav-wrapper {
  z-index: 1000;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  background: #fcfcfc;
  color: white;
  width: 1200px;
  overflow: visible;
  padding-left: 20px;
  display: flex;
  justify-content: space-around;
  .menu-item {
    display: inline-block;
    position: relative;
    margin-right: 30px;
    line-height: 60px;
    height: 100%;
    overflow: visible;
    font-size: 22px;
    font-weight: 600;
    i {
      margin-left: 5px;
      font-size: 12px;
      transition: transform 0.3s ease-in-out;
    }
    a {
      color: #303030;
      text-decoration: none;
      &:hover,
      &.router-link-exact-active {
        color: #2d62a3;
      }
    }
    .submenu {
      color: #1f2326;
      display: block;
      width: 130px;
      padding-top: 0px;
      border-radius: 5px;
      position: absolute;
      z-index: 2000;
      top: 40px;
      opacity: 0;
      pointer-events: none;
      &::before {
        display: block;
        content: "";
        width: 15px;
        height: 15px;
        background: rgba(66, 66, 66, 1);
        position: relative;
        transform: rotateZ(45deg);
        left: 40px;
        top: 12px;
      }
      .submenu-item {
        background: rgba(66, 66, 66, 1);
        color: white;
        line-height: 30px;
        padding: 10px 8px;
        box-shadow: 2px 2px 5px #333333;
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid #1f2326;
        &:nth-child(2) {
          transform: translateY(-41px);
        }
        &:nth-child(3) {
          transform: translateY(-82px);
        }
        &:nth-child(4) {
          transform: translateY(-123px);
        }
        &:nth-child(5) {
          transform: translateY(-164px);
        }
        &:last-child {
          border: none;
        }
        &:hover {
          background: rgb(4, 121, 231);
        }
      }
    }
    &:hover {
      cursor: pointer;
      color: #113f6c;
      i {
        transform: rotateZ(180deg);
      }
      .submenu {
        opacity: 1;
        pointer-events: all;
        .submenu-item {
          height: auto;
          transform: translateY(0);
        }
      }
    }
    &.active {
      color: rgb(9, 140, 248);
    }
  }
}
.el-menu {
  &.el-menu--horizontal {
    &.el-menu-demo {
      border-bottom: solid 1px gray;
    }
  }
}
</style>
