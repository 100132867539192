/**
 * api接口的统一出口
 */

// 导入其他模块的接口
import module from './module';
import personInfo from './personinfo';
import research from './research';
import publication from './publication';
import students from './students';
import members from "@/api/members";
import home from "@/api/home";
import directions from "@/api/directions";
import achievements from "@/api/achievements";
import news from "@/api/news";
import pictures from "@/api/pictures";


const API = {
    module,
    personInfo,
    research,
    publication,
    students,
    members,
    home,
    directions,
    achievements,
    news,
    pictures
    // ...
};

export default API;
