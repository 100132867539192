/**
 * 本文件只是为了写一个模板文件
 * template模板接口列表
 */

import axios from '../utils/http';
// import qs from 'qs';

const template = {

    // 查询所有
    templateList() {
        return axios.get('template/getAll');
    },

    // 根据ID查询一个
    templateQueryOne(templateId: number) {
        const params = {
            templateId,
        };
        return axios.get(
            'template/queryOne',
            { params },
        );
    },

    // Post请求添加
    templateAdd() {
        return axios.post(
            'template/add',
            // qs.stringify({ template: 'template' }),
        );
    },

    findContentByName(name: string) {
        return axios.post(
            '/content/findContentByName',
            {name},
        );
    },

};

export default template;
