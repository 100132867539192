/**
 * 个人信息接口列表
 */

// import axios from 'axios';
import axios from '../utils/http';


const personInfo = {

    // 查询个人简介数据
    findAllPersonalResumeClient(){
        return axios.get('personalResume/findAllPersonalResumeClient');
    },

    // 查询新闻数据
    findAllNewsClient(){
        return axios.get('api/v1/news/findAllNewsClient');
    },

    // 查询奖项数据
    findAllAwardHonorsClient(){
        return axios.get('api/v1/awardHonors/findAllAwardHonorsClient');
    },
};

export default personInfo;

