// import axios from 'axios';
import axios from '../utils/http';


const home = {
    // 返回所有achievements
    getHomePage() {
        return axios.get('api/v1/homepage');
    }

}

export default home;
