/**
 * 论文信息接口列表
 */

// import axios from 'axios';
import axios from '../utils/http';


const publication = {

    // 查询社会兼职数据
    findAllProfessionalActivitiesClient(){
        return axios.get('api/v1/professionalActivities/findAllProfessionalActivitiesClient');
    },

    // 查询研究领域
    findAllresearchAreasClient(){
        return axios.get('api/v1/researchAreas/findAllresearchAreasClient');
    },

    // 查询论文数据
    findAllPublicationClient(){
        return axios.get('api/v1/publication/findAllPublicationClient');
    },

    // 查询出版著作权
    findAllBookClient(){
        return axios.get('api/v1/book/findAllBookClient');
    }
    
};

export default publication;

