// zh-CN.ts
export default {

    name:"S m a r t  I o t  L a b",
    more:"More",
    language:"中文",
    visit:"visits：",
    introduction:{
       Introduction: 'Introduction',
       Enrollment: 'Enrollment',
       Trends: 'Trends',
       Directions: 'Directions',
       Achievements: 'Achievements',
    },
   
  

    research:{
        Research:'Researches',
        Project:'Research Projects',
        Direction:'Directions',
     },

    achievement:{
        Achievements: 'Achievements',
        Applications: 'Applications',
        Honors: 'Honors',
        Awards: 'Awards',
        Seminalpapers: 'Seminal Papers',
        CCFA:"CCF A",
        ESI:"ESI Highly Cited Pape"
    },

    trend:{
        Trends:"Trends",
        Labtrends:"Lab Trends"
    },
    
    Scholarlyevents:"Scholarly Events",

    team:{
        Team:"Team",
        Teachers:"Teacher",
        PhD:"PhD",
        Master:"Master",
        Homepage:"Homepage",
        Alumni:"Alumni",
        Undergraduate:"Undergraduate",

    },

    Photowall:"Photowall"
}
  