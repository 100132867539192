// import axios from 'axios';
import axios from '../utils/http';


const directions = {

    // 返回所有的directions
    getDirections() {
        return axios.get('api/v1/directions');
    }
}

export default directions;
